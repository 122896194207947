<template>
    <div v-show="isShow" ref="father" class="pro_app sub_card_bg text-color-1">
        <b-spinner ref="child" class="spi" van variant="primary"> </b-spinner>
    </div>
</template>

<script>
import { onMounted, ref } from "@vue/composition-api";

export default {
    name: "ModelLoading",
    props: {
        isShow: {
            type: Boolean,
            default: true,
        },
        size: {
            type: Number,
            default: 0.6,
        },
    },
    setup(props, { ctx, refs }) {
        const father = ref(null);
        const child = ref(null);
        onMounted(() => {
            const appStyle = window.getComputedStyle(father.value);
            const height = Number(appStyle.height.match(/\d+/g).join("."));
            const width = Number(appStyle.width.match(/\d+/g).join("."));
            const length = width > height ? height : width;
            refs["child"].style.width = length * props.size + "px";
            refs["child"].style.height = length * props.size + "px";
        });
        return {
            father,
            child,
        };
    },
};
</script>

<style lang="scss" scoped>
.pro_app {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    opacity: 0.8;

    .spi {
        height: 100px;
        width: 100px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}
</style>
