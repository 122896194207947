<template>
  <b-modal id="model-equip-config" v-model="show" :title="'绑定设备-' + (equip_options.equip_id ? '编辑' : '新增')"
           cancel-title="取消" cancel-variant="outline-secondary" centered no-close-on-backdrop ok-title="确定"
           size="lg" @ok="submit">
    <div class="text-right">
      <b-form-checkbox checked="true" inline name="check-button" switch> 显示标签</b-form-checkbox>
      <b-button v-show="equip_options.equip_id" variant="flat-danger" @click="removeEquipment">
        <feather-icon class="mr-50" icon="ZapOffIcon"/>
        解绑
      </b-button>
    </div>

    <app-timeline class="mb-2">
      <app-timeline-item variant="primary">
        <h5>设备定义</h5>
        <b-form-group>
          <b-row>
            <b-col cols="12">
              <label>设备名称</label>
              <b-form-input v-model="equip_options.equip_name" placeholder="请输入设备名称" trim/>
            </b-col>
          </b-row>
          <b-row class="mt-50">
            <b-col cols="6">
              <label>所在楼层</label>
              <b-form-input v-model="equip_options.floor" class="text-uppercase" placeholder="输入设备所在楼层" trim/>
            </b-col>
            <b-col cols="6">
              <label>所属系统</label>
              <b-form-input v-model="equip_options.system_type" placeholder="请输入所属系统名称" trim/>
            </b-col>
          </b-row>
          <b-row class="mt-50">
            <b-col cols="6">
              <label>绑定模型中的元素</label>
              <b-form-input v-model="equip_options.equip_key" placeholder="请输入模型文件中的元素名称" trim/>
            </b-col>
            <b-col cols="6">
              <label>绑定数据库中的设备</label>
              <v-select v-model="equip_options.equip_meter_code" :clearable="false"
                        :options="$store.state.jsf35.equip_meter_code_list" placeholder="绑定数据库中的设备"/>
            </b-col>
          </b-row>
        </b-form-group>
      </app-timeline-item>
      <app-timeline-item variant="primary">
        <h5>设备层级</h5>
        <b-form-group>
          <b-row>
            <b-col cols="4">
              <label>一级分类</label>
              <v-select v-model="equip_options.group_lvl_1" :clearable="false" :options="[]"
                        placeholder="请选择设备一级分类" pushTags taggable/>
            </b-col>
            <b-col cols="4">
              <label>二级分类</label>
              <v-select v-model="equip_options.group_lvl_2" :clearable="false" :disabled="!equip_options.group_lvl_1"
                        :options="[]" placeholder="请选择设备二级分类" pushTags taggable/>
            </b-col>
            <b-col cols="4">
              <label>三级分类</label>
              <v-select v-model="equip_options.group_lvl_3" :clearable="false"
                        :disabled="!equip_options.group_lvl_1 || !equip_options.group_lvl_2" :options="[]"
                        placeholder="请选择设备三级分类" pushTags taggable/>
            </b-col>
          </b-row>
        </b-form-group>
      </app-timeline-item>
      <app-timeline-item variant="primary">
        <h5>监控画面</h5>
        <b-form-group>
          <b-row class="mt-50">
            <b-col cols="12">
              <label>监控推流链接</label>
              <b-form-input v-model="equip_options.ip_address" placeholder="监控推流链接" trim/>
            </b-col>
          </b-row>
        </b-form-group>
        <!--				<h5>异常状态</h5>-->
        <!--				<b-form-group>-->
        <!--					<b-row class="mt-50">-->
        <!--						<b-col cols="6">-->
        <!--							<label>开/关机参数</label>-->
        <!--							<v-select :clearable="false" :options="equip_options.params" :reduce="(text) => text.meter_code" class="rounded" label="param_name" placeholder="开/关机参数" />-->
        <!--						</b-col>-->
        <!--						<b-col cols="4">-->
        <!--							<label>开机时的值</label>-->
        <!--							<b-form-input placeholder="开机时的值" trim />-->
        <!--						</b-col>-->
        <!--					</b-row>-->
        <!--					<b-row class="mt-50">-->
        <!--						<b-col cols="6">-->
        <!--							<label>异常参数</label>-->
        <!--							<v-select :clearable="false" :options="equip_options.params" :reduce="(text) => text.meter_code" class="rounded" label="param_name" placeholder="异常参数" />-->
        <!--						</b-col>-->
        <!--						<b-col cols="4">-->
        <!--							<label>异常时的值</label>-->
        <!--							<b-form-input placeholder="异常时的值" trim />-->
        <!--						</b-col>-->
        <!--					</b-row>-->
        <!--				</b-form-group>-->
      </app-timeline-item>
      <app-timeline-item variant="primary">
        <h5>故障处理提示</h5>
        <b-form-group>
          <b-row class="mt-50">
            <b-col cols="12">
              <label>故障处理提示<small class="text-warning ml-1">换行符：<span>&lt;br/&gt;</span></small></label>
              <b-form-input v-model="equip_options.error_handle" placeholder="故障处理提示" trim/>
            </b-col>
          </b-row>
        </b-form-group>
      </app-timeline-item>
      <app-timeline-item variant="primary">
        <h5>模型配置</h5>
        <b-form-group>
          <b-row class="mt-50">
            <b-col cols="4">
              <label>详细模型</label>
              <b-form-input v-model="equip_options.detail_model_key" trim/>
            </b-col>
            <b-col cols="4">
              <label>模型旋转 (x, y, z)</label>
              <b-form-input v-model="equip_options.model_rotate" placeholder="请输入模型旋参数 (x, y, z)" trim/>
            </b-col>
          </b-row>
        </b-form-group>
      </app-timeline-item>
    </app-timeline>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import AppTimeline from "@core/components/app-timeline/AppTimeline";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";
import {onMounted, reactive, toRefs, watch} from "@vue/composition-api";
import {routerParams} from "@/libs/utils/routerParams";
import {
  addEquipment,
  deleteEquipment,
  deleteParam,
  getDatasourceList,
  getFieldList,
  getLatestValue,
  getTableList,
  getValueList,
  updateEquipment
} from "@/api/jsf35";
import {showToast} from "@/libs/utils/showToast";
import store from "@/store";
import {showConfirm} from "@/libs/utils/showConfirm";

export default {
  name: "ModelConfig",
  components: {
    vSelect,
    AppTimeline,
    AppTimelineItem,
  },
  props: ["modelKey"],
  setup(props, {emit}) {
    //console.log("props", props);
    const project_id = routerParams("project_id");
    const system_key = routerParams("system_key");
    const equip_item = {
      equip_id: null,
      equip_name: "",
      system_type: system_key,
      detail_model_key: "",
      model_dbids: [],
      equip_key: store.state.jsf35.equip_key_selected,
      equip_meter_code: "",
      model_rotate: "",
      floor: "",
      group_lvl_1: "",
      group_lvl_2: "",
      group_lvl_3: "",
      params: [],
      ip_address: "",
      error_handle: ""
    };
    const event = reactive({
      show: false,
      param_type_list: [
        {text: "开关量", value: "on/off"},
        {text: "数值量", value: "value"},
      ],
      equip_options: equip_item,
    });

    //单个参数
    const param_item = {
      param_id: null,
      equip_id: null,
      param_name: "",
      param_type: "value",
      series_field: "",
      series_value: "",
      value_field: "",
      cur_value: null,
      popover_show: true,
      control_panel_show: true,
      command_active: false,
      command_value: "",
      unit: "",
      unit_on: "",
      unit_off: "",
    };

    watch(
        () => store.state.jsf35.dbId_selected,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            event.equip_options.model_dbids = newVal;
          }
        }
    );

    watch(
        () => store.state.jsf35.equip_selected,
        (val) => {
          let options = val || equip_item;
          options.equip_key = options.equip_key || store.state.jsf35.equip_key_selected;
          event.equip_options = JSON.parse(JSON.stringify(options));
          // console.log("equip_selected", event.equip_options);
        }
    );

    watch(
        () => store.state.jsf35.equip_hovered,
        (val) => {
          let options = val || equip_item;
          options.equip_key = options.equip_key || store.state.jsf35.equip_key_selected;
          event.equip_options = JSON.parse(JSON.stringify(options));
          // console.log("equip_hovered", event.equip_options);
        }
    );

    watch(
        () => event.equip_options.equip_meter_code,
        (val) => {
          if (val) {
            if (!event.equip_options.floor) {
              let floor = val.split("_")[2];
              if (floor.startsWith("F")) {
                let floor_num = parseInt(floor.slice(1));
                if (floor_num > 3) {
                  floor_num += 1;
                  floor = "F" + floor_num;
                }
                event.equip_options.floor = floor;
              } else if (floor === "B4") {
                floor = "B5";
              }
              event.equip_options.floor = floor;
            }
          }
        }
    );

    // const fetchEquipKeyList = async () => {
    //   let res = getEquipKeyList();
    //   if (res.code === 0) {
    //     event.equip_options.equip_key = res.data.length > 0 ? res.data[0].equip_key : "";
    //   }
    // };

    //删除参数绑定
    const removeEquipment = async () => {
      showConfirm("确定要删除该设备吗？", () => {
        deleteEquipment(event.equip_options.equip_id)
            .then((res) => {
              store.commit("jsf35/setEquipTree", res.data.tree);
              showToast(null, "成功", "设备删除成功");
              event.show = false;
            })
            .catch((error) => {
              showToast(error);
            });
      });
    };

    //保存设备绑定
    const submit = () => {
      let equip_options = JSON.parse(JSON.stringify(event.equip_options));
      equip_options.project_id = project_id;

      if (!equip_options.equip_id) {
        //新增设备
        addEquipment(equip_options)
            .then((res) => {
              store.commit("jsf35/setEquipTree", res.data.tree);
              showToast(null, "成功", "设备添加成功");
              emit("updateModel", {
                detail_model_key: equip_options.detail_model_key,
                model_dbids: equip_options.model_dbids,
              });
            })
            .catch((error) => {
              showToast(error);
            });
      } else {
        //更新设备
        updateEquipment(equip_options)
            .then((res) => {
              store.commit("jsf35/setEquipTree", res.data.tree);
              showToast(null, "成功", "设备更新成功");
              emit("updateModel", {
                detail_model_key: equip_options.detail_model_key,
                model_dbids: equip_options.model_dbids,
              });
            })
            .catch((error) => {
              showToast(error);
            });
      }
    };

    //添加参数
    const addParams = () => {
      let param_options = JSON.parse(JSON.stringify(param_item));
      param_options.equip_id = event.equip_options.equip_id;
      event.equip_options.params.push(param_options);
    };
    //删除参数
    const delParam = (index, param_id) => {
      showConfirm("确定删除此参数？", () => {
        if (param_id) {
          deleteParam(param_id)
              .then((res) => {
                showToast(null, "成功", "参数删除成功");
                event.equip_options.params.splice(index, 1);
              })
              .catch((error) => {
                showToast(error);
              });
        } else {
          event.equip_options.params.splice(index, 1);
        }
      });
    };

    //获取数据源列表
    const fetchDatasourceList = (project_id = project_id) => {
      event.datasource_list = [];
      event.table_list = [];
      event.field_list = [];
      getDatasourceList({project_id})
          .then((res) => {
            event.datasource_list = res.data.list;
          })
          .catch((error) => {
            showToast(error);
          });
    };

    //获取数据表列表
    const fetchTableList = (datasource_id = event.equip_options.datasource_id) => {
      event.table_list = [];
      event.field_list = [];
      getTableList(datasource_id)
          .then((res) => {
            event.table_list = res.data;
          })
          .catch((error) => {
            showToast(error);
          });
    };

    //获取字段列表
    const fetchFieldList = (datasource_id = event.equip_options.datasource_id, table = event.equip_options.table) => {
      event.field_list = [];
      getFieldList(datasource_id, table)
          .then((res) => {
            event.table_pk = "";
            event.field_list = res.data.fields.map((field) => {
              //获取主键
              if (field.primary) {
                event.table_pk = field.name;
                console.log("event.table_pk", event.table_pk);
              }
              return {
                text: field.name + "(" + field.type + ")",
                value: field.name,
              };
            });
          })
          .catch((error) => {
            showToast(error);
          });
    };

    //获取字段的值列表
    const fetchValueList = (field, datasource_id = event.equip_options.datasource_id, table = event.equip_options.table) => {
      if (field) {
        let value_list = JSON.parse(JSON.stringify(event.value_list));
        event.value_list = {};
        getValueList(datasource_id, table, field)
            .then((res) => {
              value_list[field] = res.data;
              event.value_list = value_list;
            })
            .catch((error) => {
              showToast(error);
            });
      } else {
        showToast(null, "异常", "请选择字段");
      }
    };

    //获取最新值
    const fetchLatestValue = (seriesField, seriesValue, valueField, datasource_id = event.equip_options.datasource_id, table = event.equip_options.table, table_pk = event.table_pk) => {
      if (seriesField && seriesValue && valueField) {
        getLatestValue({
          datasource_id,
          table,
          table_pk,
          seriesField,
          seriesValue,
          valueField,
        })
            .then((res) => {
              event.equip_options.params.forEach((param) => {
                if (param.series_field === seriesField && param.series_value === seriesValue && param.value_field === valueField) {
                  param.cur_value = res.data;
                }
              });
            })
            .catch((error) => {
              showToast(error);
            });
      } else {
        showToast(null, "异常", "请检查参数");
      }
    };

    const changeShow = (index, type) => {
      event.equip_options.params[index][type] = !event.equip_options.params[index][type];
      if (type === "command_active") {
        event.equip_options.params[index][type] === true && (event.equip_options.params[index]["popover_show"] = false);
      }
      if (type === "control_panel_show") {
        event.equip_options.params[index]["control_panel_show"] && (event.equip_options.params[index].popover_show = !event.equip_options.params[index]["control_panel_show"]);
      }
      if (type === "popover_show") {
        if (event.equip_options.params[index]["popover_show"]) {
          event.equip_options.params[index].control_panel_show = !event.equip_options.params[index]["popover_show"];
          event.equip_options.params[index].command_active = !event.equip_options.params[index]["popover_show"];
        }
      }
    };

    onMounted(() => {
    });

    return {
      ...toRefs(event),
      submit,
      removeEquipment,
      changeShow,
    };
  },
};
</script>

<style lang="scss" scoped></style>
